body {
  margin: 0;
  padding: 0;
  height: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: "Montserrat" !important;
}

.custom-svg-text {
  font-size: 28px;
  font-weight: 800;
  width: 5px !important;
}

/* chakra-slider__filled-track  */
.css-1m7iqxj {
  background: transparent !important;
}

.css-1v8vhzx {
  background-color: #fff50a !important;
}

.chakra-checkbox__control {
  padding: 5px;
}

.custom-checkbox input:checked + .chakra-checkbox__control {
  background-color: black; /* Change this to your desired background color */
  border-color: black; /* Change this to match the background color if needed */
}

.custom-svg-sec5 {
  font-size: 102px;
  font-weight: 800;
}

.footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  margin-top: auto;
}

.fade-container {
  opacity: 1;
  transition: opacity 0.5s ease-in-out;
}

.fade-out {
  opacity: 0;
}

.fade-in {
  opacity: 1;
}

.resp-tabs-hidden {
  display: none !important;
}
.mobile_active {
  display: none !important;
}
@media only screen and (max-width: 600px) {
  .resp-tabs {
    display: none !important;
  }

  .avatar-resp {
    display: none !important;
  }

  .mobile_inactive {
    display: none !important;
  }

  .resp-tabs-hidden {
    display: block !important;
  }

  .mobile_active {
    display: block !important;
  }

  .resp-svg {
    display: none !important;
  }
}

.dnd {
  touch-action: none;
}

.loader {
  width: 120px;
  height: 20px;
  border-radius: 20px;
  background: linear-gradient(yellow 0 0) 0/0% no-repeat lightblue;
  animation: l2 2s infinite steps(10);
}
@keyframes l2 {
  100% {
    background-size: 110%;
  }
}
